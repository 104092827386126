import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { registerLocaleData } from '@angular/common';
import { Language } from '@app/core/services/language/language-base.service';
import langDa from '@angular/common/locales/da';
import langNb from '@angular/common/locales/nb';
import langEn from '@angular/common/locales/en-GB';
registerLocaleData(langDa);
registerLocaleData(langNb);
registerLocaleData(langEn);
import 'moment/locale/da.js';
import 'moment/locale/nb.js';
import 'moment/locale/en-gb.js';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private injector: Injector,
    private translateService: TranslateService
  ) {}

  private supportedLanguages: Language[] = [
    new Language(
      'English',
      'en',
      null,
      'en-gb',
      'en-GB',
      ['en', 'en-AU', 'en-BZ', 'en-CA', 'en-CB', 'en-GB', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-TT', 'en-US', 'en-ZA', 'en-ZW'],
      'en',
      'en-us'
    ),
    new Language('Dansk', 'da', null, 'da', 'da', ['da', 'da-dk'], 'da', 'da'),
    new Language('Norsk', 'no', null, 'nb', 'nb', ['nb', 'nb-NO', 'nn-NO'], 'no', 'no'),
  ];

  private defaultLanguage: Language = this.supportedLanguages[0];

  public getDefaultLanguage(): Language {
    return this.defaultLanguage;
  }

  public getSupportedLanguages(): Language[] {
    return this.supportedLanguages;
  }

  public setLanguage(language: Language): void {
    this.translateService.use(language.languageKey);
    moment.locale(language.momentKey);
  }

  public getCurrentLanguage(): Language {
    const lan = this.supportedLanguages.find(l => l.languageKey === this.translateService.currentLang);

    return lan ? lan : this.defaultLanguage;
  }
}
